CustomCarousel.css .carousel-item-container {
  display: flex;
  justify-content: space-between;

}

.carousel-item-image {
    width: 100%;
    height: 100%;
    object-fit: contain; /* This ensures the images fit within the container without being cut */
  }

  


.carousel-item-image.active {
  filter: blur(0); /* Remove blur effect from the active (current) image */
  width: 60%;


}

.carousel-item-image.blur {


  filter: blur(8px); /* Apply blur effect to non-active images */
  width: 20%;
}
